.spinAnimation {
    animation: spin 3s cubic-bezier(0.25, 0.1, 0.25, 1) 1;
    animation-delay: 2s;
    height: 50px;
    width: 50px;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-360deg);
  }
  90% {
    transform: rotate(-372deg);
  }
  100% {
    transform: rotate(-360deg);
  }
    
}