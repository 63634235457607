@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=General+Sans:wght@500;600;700&display=swap"); */
body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --text-md-normal: Inter;
  /* --body-large-medium: "General Sans"; */

  /* font sizes */
  --text-md-normal-size: 16px;
  --text-lg-semibold-size: 18px;
  --display-md-semibold-size: 36px;
  --display-sm-semibold-size: 30px;
  --text-sm-medium-size: 14px;
  --heading-h3-size: 32px;
  --font-size-4xs-9: 8.9px;
  --font-size-5xs-4: 7.4px;
  --font-size-6xs-2: 6.2px;
  --font-size-4xs-7: 8.7px;
  --heading-h2-size: 40px;

  /* Colors */
  --base-white: #fff;
  --greyscale-50: #fafafa;
  --greyscale-900: #111827;
  --gray-900: #101828;
  --color-gray-100: rgba(255, 255, 255, 0.5);
  --persian-pink: #f76dac;
  --greyscale-600: #718096;
  --gray-500: #667085;
  --gray-300: #d0d5dd;
  --gray-700: #344054;
  --greyscale-100: #f9fafb;
  --greyscale-200: #f1f2f4;
  --greyscale-300: #eeeff2;
  --others-blue: #0062ff;
  --greyscale-500: #a0aec0;
  --primary-200: #ceefdf;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-5xl: 24px;
  --gap-7xs: 6px;
  --gap-10xs: 3px;
  --gap-10xs-5: 2.5px;

  /* Paddings */
  --padding-21xl: 40px;
  --padding-13xl: 32px;
  --padding-5xl: 24px;
  --padding-3xs: 10px;
  --padding-base: 16px;
  --padding-sm: 14px;
  --padding-7xs-9: 5.9px;
  --padding-8xs: 5px;

  /* border radiuses */
  --br-9xl: 28px;
  --br-xs: 12px;
  --br-5xs: 8px;
  --br-3xs: 10px;
  --br-11xs-5: 1.5px;
  --br-10xs: 3px;
  --br-8xs: 5px;

  /* Effects */
  --background-blur-lg: blur(24px);
  --background-blur-sm: blur(8px);
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);

  /* Z-index */
  .swal2-popup {
    z-index: 100000;
  }
}